<template>
	<el-tabs :before-leave="go_tab">
		<el-tab-pane lazy label="企业管理">
			<madmin style="margin-bottom:10px" ref="main0" title="企业" table_name="Company">
				<!-- 弹出编辑模态窗口 -->
				<template #writeForm="{writeForm}">
					<el-row>
						<el-form-item prop="name" label="企业名称">
							<el-input clearable v-model="writeForm.name" placeholder="" style="width: 480px;" />
						</el-form-item>
						<el-form-item prop="address" label="详细地址">
							<el-input clearable v-model="writeForm.address" placeholder="" style="width: 480px;" />
						</el-form-item>
					</el-row>
					<el-row>
						<el-form-item prop="trade" label="所属行业">
							<el-input clearable v-model="writeForm.trade" placeholder="" style="width: 480px;" />
						</el-form-item>
						<el-form-item prop="code" label="统一社会信用代码">
							<el-input clearable v-model="writeForm.code" placeholder="" style="width: 480px;" />
						</el-form-item>
						<el-form-item prop="level" label="能耗级别">
							<el-input clearable v-model="writeForm.level" placeholder="" style="width: 480px;" />
						</el-form-item>
					</el-row>
					<el-row>
						<el-form-item prop="lng" label="经度">
							<el-input clearable v-model="writeForm.lng" placeholder="" style="width: 120px;" />
						</el-form-item>
						<el-form-item prop="lat" label="纬度">
							<el-input clearable v-model="writeForm.lat" placeholder="" style="width: 120px;" />
						</el-form-item>
					</el-row>
					<el-row>
						<el-form-item label="版本" prop="ver">
							<el-radio-group v-model="writeForm.ver">
								<el-radio-button label="0">体验版</el-radio-button>
								<el-radio-button label="1">专业版</el-radio-button>
							</el-radio-group>
						</el-form-item>
					</el-row>
					<template v-if="writeForm.ver==1">
						<el-row>
							<el-form-item prop="alert_mobile" label="接收报警短信手机号">
								<el-input clearable v-model="writeForm.alert_mobile" placeholder="" style="width: 120px;" />
							</el-form-item>
							<el-form-item prop="timer" label="数据上传频率">
								<el-select v-model="writeForm.timer" filterable clearable>
									<el-option value="300" label="5分钟" />
									<el-option value="300" label="10分钟" />
									<el-option value="900" label="15分钟" />
									<el-option value="1200" label="20分钟" />
									<el-option value="1800" label="30分钟" />
									<el-option value="3600" label="1小时" />
								</el-select>
							</el-form-item>
						</el-row>
						<el-row>
							<el-form-item label="上传企业3D全景图" prop="bg_img">
								<mupload-avatar :photo.sync="writeForm.bg_img" action="http://zhny.rayoai.com/api/admin/Company/upload_photo" />
							</el-form-item>
						</el-row>
					</template>
					<el-row>
						<el-form-item label="拾取坐标系统">
							<el-link type="primary" href="http://api.map.baidu.com/lbsapi/getpoint/index.html" target="_blank">前往百度拾取坐标系统 >></el-link>
						</el-form-item>
					</el-row>
					<el-row>
						<el-form-item label="企业信息查询">
							<el-link type="primary" :href="'https://www.tianyancha.com/search?key='+writeForm.name" target="_blank">前往天眼查 >></el-link>
						</el-form-item>
					</el-row>
					<el-row>
						<el-form-item label=" ">
							<h6>注册时间：{{writeForm.create_time}}</h6>
						</el-form-item>
					</el-row>
				</template>
				<!-- 搜索框 -->
				<template #searchForm="{searchForm,show_more_search}">
					<el-form-item label="企业名称" prop="name">
						<el-input v-model="searchForm.name" clearable />
					</el-form-item>
					<el-form-item label="详细地址" prop="address" v-show="show_more_search">
						<el-input v-model="searchForm.address" clearable />
					</el-form-item>
					<el-form-item label="所属行业" prop="trade" v-show="show_more_search">
						<el-input v-model="searchForm.trade" clearable />
					</el-form-item>
					<el-form-item label="统一社会信用代码" prop="code" v-show="show_more_search">
						<el-input v-model="searchForm.code" clearable />
					</el-form-item>
					<el-form-item label="能耗级别" prop="level" v-show="show_more_search">
						<el-input v-model="searchForm.level" clearable />
					</el-form-item>
				</template>
				<!-- 列表 -->
				<template #table>
					<el-table-column label="" width="90px">
						<template slot-scope="scope">
							<el-image :lazy="true" :src="scope.row.bg_img" width="80px" fit="cover" :preview-src-list="[scope.row.bg_img]">
								<div slot="error" class="image-slot">
									暂无全景图
								</div>
							</el-image>
						</template>
					</el-table-column>
					<el-table-column label="">
						<template slot-scope="scope">
							<h4 style="color:#2C6DD2;margin:5px 0">
								<el-tag v-if="scope.row.ver==0" size="small" style="margin-right:10px" type="info" effect="dark">体验版</el-tag>
								<el-tag v-else size="small" style="margin-right:10px" type="warning" effect="dark">专业版</el-tag>
								{{scope.row.name}}
							</h4>
							<p v-if="scope.row.ver!=0" style="margin:5px 0"><i style="color:#2C6DD2" class="iconfont el-icon-gpsfixed"></i> 专业版配置：数据上传频率 <span style="color:#2C6DD2">{{scope.row.timer/60}}分钟</span> / 接收报警短信手机号 <span style="color:#2C6DD2">{{scope.row.alert_mobile}}</span></p>
							<p style="margin:5px 0"><i style="color:#2C6DD2" class="iconfont el-icon-dizhi"></i> 企业税号：{{scope.row.code}} / 所属行业 {{scope.row.trade}} / {{scope.row.level}}能耗</p>
							<p style="margin:5px 0"><i style="color:#2C6DD2" class="iconfont el-icon-dizhi"></i> 详细地址：{{scope.row.address}}</p>
							<p style="margin:5px 0"><i style="color:#2C6DD2" class="iconfont el-icon-gpsfixed"></i> GPS定位：经度{{scope.row.lng}}° / 纬度{{scope.row.lat}}°</p>
							<p style="margin:5px 0"><i style="color:#2C6DD2" class="iconfont el-icon-gpsfixed"></i> 企业资产：监测设备{{scope.row.device_num}}个 / 注册用户{{scope.row.admin_num}}人</p>
							<p style="margin:5px 0">
								<i style="color:#2C6DD2" class="iconfont el-icon-gpsfixed"></i> 注册时间：{{scope.row.create_time}}
								<el-link style="float:right" icon="el-icon-delete" type="primary" @click="del_btn('main0', scope.row.id)" v-if="role!='普通管理员'">删除</el-link>
								<el-link style="float:right;margin-right:10px" icon="el-icon-edit" type="primary" @click="edit_btn('main0', scope.row.id)" v-if="role!='普通管理员'">编辑</el-link>
							</p>
						</template>
					</el-table-column>
				</template>
			</madmin>
		</el-tab-pane>
		<el-tab-pane lazy label="用户管理">
			<madmin style="margin-bottom:10px" ref="main2" title="用户" table_name="User">
				<!-- 弹出编辑模态窗口 -->
				<template #writeForm=" {writeForm}">
					<el-row>
						<el-form-item label="上传头像" prop="head">
							<mupload-avatar :photo.sync="writeForm.head" action="http://zhny.rayoai.com/api/admin/User/upload_photo" />
						</el-form-item>
					</el-row>
					<el-row>
						<el-form-item prop="nickname" label="姓名">
							<el-input v-model="writeForm.nickname" />
						</el-form-item>
					</el-row>
					<el-row>
						<el-form-item label="性别" prop="sex">
							<el-radio-group v-model="writeForm.sex">
								<el-radio-button label="1">男</el-radio-button>
								<el-radio-button label="2">女</el-radio-button>
							</el-radio-group>
						</el-form-item>
						<el-form-item prop="birthday" label="出生日期">
							<el-date-picker v-model="writeForm.birthday" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" align="right" style="width: 159px;">
							</el-date-picker>
						</el-form-item>
					</el-row>
					<el-row>

					</el-row>
					<el-row>
						<el-form-item prop="mobile" label="手机号">
							<el-input v-model="writeForm.mobile" />
						</el-form-item>
						<el-form-item prop="email" label="邮箱">
							<el-input v-model="writeForm.email" />
						</el-form-item>
					</el-row>
					<el-row>
						<el-form-item prop="job" label="岗位">
							<el-input v-model="writeForm.job" />
						</el-form-item>
						<el-form-item prop="title" label="职称">
							<el-input v-model="writeForm.title" />
						</el-form-item>
					</el-row>
					<el-row>
						<el-form-item prop="pwd" label="密码">
							<el-input v-model="writeForm.pwd" />
						</el-form-item>
					</el-row>
					<el-row>
						<el-form-item label="权限分配" prop="role">
							<el-radio-group v-model="writeForm.role">
								<el-radio-button label="1">超级管理员</el-radio-button>
								<el-radio-button label="2">企业管理员</el-radio-button>
								<el-radio-button label="3">普通管理员</el-radio-button>
							</el-radio-group>
						</el-form-item>
					</el-row>
					<el-row>
						<el-form-item prop="company_id" label="所属企业" v-show="writeForm.role==2">
							<el-select v-model="writeForm.company_id" placeholder="请选择" filterable clearable style="width: 480px;">
								<el-option v-for="item in select_company" :key="item.id" :label="item.name" :value="item.id" />
							</el-select>
						</el-form-item>
					</el-row>
					<el-row>
						<el-form-item label=" ">
							<h6>创建时间：{{writeForm.create_time}}<br />修改时间：{{writeForm.update_time}}</h6>
						</el-form-item>
					</el-row>
				</template>
				<!-- 搜索框 -->
				<template #searchForm="{searchForm,show_more_search}">
					<el-form-item label="姓名" prop="nickname">
						<el-input v-model="searchForm.nickname" clearable />
					</el-form-item>
					<el-form-item label="手机号" prop="mobile">
						<el-input v-model="searchForm.mobile" clearable />
					</el-form-item>
					<el-form-item label="登录时间" prop="login_time" v-show="show_more_search">
						<el-date-picker v-model="searchForm.login_time" type="daterange" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd" align="right"></el-date-picker>
					</el-form-item>

					<el-form-item label="权限" prop="role" v-show="show_more_search">
						<el-radio-group v-model="searchForm.role" @change="search_btn('main2')">
							<el-radio-button label="1">超级管理员</el-radio-button>
							<el-radio-button label="2">企业管理员</el-radio-button>
							<el-radio-button label="3">普通管理员</el-radio-button>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="管理企业名称" prop="company_name" v-show="show_more_search" v-if="searchForm.role==2">
						<el-input v-model="searchForm.company_name" clearable />
					</el-form-item>
				</template>
				<!-- 列表 -->
				<template #table>
					<el-table-column label="" width="90px">
						<template slot-scope="scope">
							<el-image :lazy="true" :src="scope.row.head" width="80px" fit="cover" :preview-src-list="[scope.row.head]" />
						</template>
					</el-table-column>
					<el-table-column label="">
						<template slot-scope="scope">
							<h4 style="color:#2C6DD2;margin:5px 0">
								<span v-if="scope.row.role=='企业管理员'">{{scope.row.company_name}} - </span>
								<span v-else>超级管理员 - </span>{{scope.row.nickname}}
								<i v-if="scope.row.sex==2" class="iconfont el-icon-fenzu" style="color:#ffadd7"></i>
								<i v-else-if="scope.row.sex==1" class="iconfont el-icon-fenzu1" style="color:#0ab3fc"></i>
							</h4>
							<p style="margin:5px 0"><i style="color:#2C6DD2" class="iconfont el-icon-wode"></i> 基本信息：手机号 {{scope.row.mobile}} / 邮箱 {{scope.row.email}} <span v-show="scope.row.birthday!=''&&scope.row.birthday!=null">/ 出生日期 {{scope.row.birthday}}</span></p>
							<p style="margin:5px 0"><i style="color:#2C6DD2" class="iconfont el-icon-wode"></i> 工作信息：岗位 {{scope.row.job}} / 职称 {{scope.row.title}} </p>
							<p style="margin:5px 0"><i style="color:#2C6DD2" class="iconfont el-icon-63"></i> 操作日志：注册时间 {{scope.row.add_time}} / 登录时间 {{scope.row.login_time}}</p>
							<p>
								<el-tag size="small" style="margin-right:10px">登录 {{scope.row.login_num}} 次</el-tag>
								<el-link style="float:right" icon="el-icon-delete" type="primary" @click="del_btn('main2', scope.row.id)" v-if="role!='普通管理员'">删除</el-link>
								<el-link style="float:right;margin-right:10px" icon="el-icon-edit" type="primary" @click="edit_btn('main2', scope.row.id)" v-if="role!='普通管理员'">编辑</el-link>
							</p>
						</template>
					</el-table-column>
				</template>
			</madmin>
		</el-tab-pane>
		<el-tab-pane lazy label="回路管理">
			<madmin style="margin-bottom:10px" ref="main3" title="回路" table_name="company_line" table_show_header>
				<!-- 弹出编辑模态窗口 -->
				<template #writeForm="{writeForm}">
					<el-row>
						<el-form-item prop="company_id" label="所属企业">
							<el-select v-model="writeForm.company_id" placeholder="请选择" filterable clearable style="width: 480px;">
								<el-option v-for="item in select_company" :key="item.id" :label="item.name" :value="item.id" />
							</el-select>
						</el-form-item>
					</el-row>
					<el-row>
						<el-form-item label="回路类型" prop="line_type">
							<el-radio-group v-model="writeForm.line_type">
								<el-radio-button label="1">能源回路</el-radio-button>
								<el-radio-button label="0">环境回路</el-radio-button>
							</el-radio-group>
						</el-form-item>
					</el-row>
					<el-row>
						<el-form-item prop="name" label="回路名称">
							<el-input clearable v-model="writeForm.name" placeholder="" style="width: 480px;" />
						</el-form-item>
					</el-row>
					<h4>统计框位置</h4>
					<el-row>
						<el-form-item prop="tag_x" label="左边距x(%)">
							<el-input clearable v-model="writeForm.tag_x" placeholder="" style="width: 80px;" />
						</el-form-item>
						<el-form-item prop="tag_y" label="上边距y(%)">
							<el-input clearable v-model="writeForm.tag_y" placeholder="" style="width: 80px;" />
						</el-form-item>
					</el-row>
					<h4>回路图片(仅体验版需要设置)</h4>
					<el-row>
						<el-form-item prop="line_pic_src" label="回路图片">
							<el-radio v-model="writeForm.line_pic_src" label="http://zhny.rayoai.com/video/1.png">
								<img src="http://zhny.rayoai.com/video/1.png" width="80" />
							</el-radio>
							<el-radio v-model="writeForm.line_pic_src" label="http://zhny.rayoai.com/video/2.png">
								<img src="http://zhny.rayoai.com/video/2.png" width="80" />
							</el-radio>
							<el-radio v-model="writeForm.line_pic_src" label="http://zhny.rayoai.com/video/3.png">
								<img src="http://zhny.rayoai.com/video/3.png" width="80" />
							</el-radio>
							<el-radio v-model="writeForm.line_pic_src" label="http://zhny.rayoai.com/video/4.png">
								<img src="http://zhny.rayoai.com/video/4.png" width="80" />
							</el-radio>
							<el-radio v-model="writeForm.line_pic_src" label="http://zhny.rayoai.com/video/5.png">
								<img src="http://zhny.rayoai.com/video/5.png" width="80" />
							</el-radio>
							<el-radio v-model="writeForm.line_pic_src" label="http://zhny.rayoai.com/video/6.png">
								<img src="http://zhny.rayoai.com/video/6.png" width="80" />
							</el-radio>
							<el-radio v-model="writeForm.line_pic_src" label="http://zhny.rayoai.com/video/7.png">
								<img src="http://zhny.rayoai.com/video/7.png" width="80" />
							</el-radio>
							<el-radio v-model="writeForm.line_pic_src" label="http://zhny.rayoai.com/video/8.png">
								<img src="http://zhny.rayoai.com/video/8.png" width="80" />
							</el-radio>
							<el-radio v-model="writeForm.line_pic_src" label="http://zhny.rayoai.com/video/9.png">
								<img src="http://zhny.rayoai.com/video/9.png" width="80" />
							</el-radio>
							<el-radio v-model="writeForm.line_pic_src" label="http://zhny.rayoai.com/video/10.png">
								<img src="http://zhny.rayoai.com/video/10.png" width="80" />
							</el-radio>
							<el-radio v-model="writeForm.line_pic_src" label="http://zhny.rayoai.com/video/11.png">
								<img src="http://zhny.rayoai.com/video/11.png" width="80" />
							</el-radio>
							<el-radio v-model="writeForm.line_pic_src" label="http://zhny.rayoai.com/video/12.png">
								<img src="http://zhny.rayoai.com/video/12.png" width="80" />
							</el-radio>
							<el-radio v-model="writeForm.line_pic_src" label="http://zhny.rayoai.com/video/13.png">
								<img src="http://zhny.rayoai.com/video/13.png" width="80" />
							</el-radio>
						</el-form-item>
					</el-row>
					<el-row>
						<el-form-item prop="line_pic_w" label="图片宽度(px)">
							<el-input clearable v-model="writeForm.line_pic_w" placeholder="" style="width: 80px;" />
						</el-form-item>
						<el-form-item prop="line_pic_h" label="图片高度(px)">
							<el-input clearable v-model="writeForm.line_pic_h" placeholder="" style="width: 80px;" />
						</el-form-item>
					</el-row>
				</template>
				<!-- 搜索框 -->
				<template #searchForm="{searchForm,show_more_search}">
					<el-form-item label="所属企业" prop="company_name" v-show="show_more_search">
						<el-select v-model="searchForm.company_id" placeholder="请选择" filterable clearable style="width: 480px;">
							<el-option v-for="item in select_company" :key="item.id" :label="item.name" :value="item.id" />
						</el-select>
					</el-form-item>
					<el-form-item label="回路类型" prop="line_type">
						<el-radio-group v-model="searchForm.line_type">
							<el-radio-button label="">全部</el-radio-button>
							<el-radio-button label="1">能源回路</el-radio-button>
							<el-radio-button label="0">环境回路</el-radio-button>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="所属回路" prop="name">
						<el-input v-model="searchForm.name" clearable />
					</el-form-item>
				</template>
				<!-- 列表 -->
				<template #table>
					<el-table-column align="center" label="回路基本信息">
						<el-table-column align="center" label="所属企业" prop="company_name" />
						<el-table-column align="center" label="回路名称" prop="name" />
						<el-table-column align="center" label="回路类型" prop="line_type" />
					</el-table-column>
					<el-table-column align="center" label="统计框位置">
						<el-table-column align="center" label="左边距%" prop="tag_x" width="80" />
						<el-table-column align="center" label="上边距%" prop="tag_y" width="80" />
					</el-table-column>
					<el-table-column label="回路图片">
						<el-table-column align="center" label="宽度(px)" prop="line_pic_w" width="80" />
						<el-table-column align="center" label="高度(px)" prop="line_pic_h" width="80" />
						<el-table-column label="图片" width="90px">
							<template slot-scope="scope">
								<el-image :lazy="true" :src="scope.row.line_pic_src" width="80px" fit="cover" :preview-src-list="[scope.row.line_pic_src]" />
							</template>
						</el-table-column>
					</el-table-column>
					<el-table-column align="center" label="操作" width="120">
						<template slot-scope="scope">
							<p style="margin:5px 0">
								<el-link style="margin-right:10px" icon="el-icon-edit" type="primary" @click="edit_btn('main3', scope.row.id)" v-if="role!='普通管理员'">编辑</el-link>
								<el-link icon="el-icon-delete" type="primary" @click="del_btn('main3', scope.row.id)" v-if="role!='普通管理员'">删除</el-link>
							</p>
						</template>
					</el-table-column>
				</template>
			</madmin>
		</el-tab-pane>
		<el-tab-pane lazy label="监测点管理">
			<madmin style="margin-bottom:10px" ref="main5" title="监测点" table_name="Company_point" table_show_header>
				<!-- 弹出编辑模态窗口 -->
				<template #writeForm="{writeForm}">
					<el-row>
						<el-form-item prop="company_id" label="所属企业">
							<el-select v-model="writeForm.company_id" placeholder="请选择" filterable clearable style="width: 180px;" @change="get_select_company_line('main5')">
								<el-option v-for="item in select_company" :key="'main5-select-company-'+item.id" :label="item.name" :value="item.id" />
							</el-select>
						</el-form-item>
						<el-form-item v-if="writeForm.company_id>0" prop="line_id" label="所属回路">
							<el-select v-model="writeForm.line_id" placeholder="请选择" filterable clearable style="width: 180px;">
								<el-option v-for="item in select_company_line" :key="'main5-select-line-'+item.id" :label="item.name" :value="item.id" />
							</el-select>
						</el-form-item>
						<el-form-item prop="name" label="监测点名称">
							<el-input clearable v-model="writeForm.name" placeholder="" style="width: 180px;" />
						</el-form-item>
					</el-row>
				</template>
				<!-- 搜索框 -->
				<template #searchForm="{searchForm}">
					<el-form-item prop="company_id" label="监测企业">
						<el-select v-model="searchForm.company_id" placeholder="请选择" filterable clearable style="width: 180px;" @change="get_select_company_line_search('main5')">
							<el-option value="" label="全部" />
							<el-option v-for="item in select_company" :key="'main5-sselect-company-'+item.id" :label="item.name" :value="item.id" />
						</el-select>
					</el-form-item>
					<el-form-item prop="line_id" label="监测回路">
						<el-select v-model="searchForm.line_id" placeholder="请选择" filterable clearable style="width: 180px;" @change="get_select_company_point_search">
							<el-option value="" label="全部" />
							<el-option v-for="item in select_company_line" :key="'main5-sselect-line-'+item.id" :label="item.name" :value="item.id" />
						</el-select>
					</el-form-item>
					<el-form-item label="监测点名称" prop="name">
						<el-input v-model="searchForm.name" clearable />
					</el-form-item>
				</template>
				<!-- 列表 -->
				<template #table>
					<el-table-column align="center" label="所属企业" prop="company_name" />
					<el-table-column align="center" label="所属回路" prop="line_name" />
					<el-table-column align="center" label="监测点" prop="name" />
				</template>
			</madmin>
		</el-tab-pane>
		<el-tab-pane lazy label="设备管理">
			<madmin style="margin-bottom:10px" ref="main1" title="设备" table_name="Device" table_show_header>
				<!-- 弹出编辑模态窗口 -->
				<template #writeForm="{writeForm}">
					<h4>基本信息</h4>
					<el-row>
						<el-form-item prop="type" label="设备类型">
							<el-select v-model="writeForm.type" filterable clearable @change="set_device_level">
								<el-option value="0" label="普通电表" />
								<el-option value="1" label="新能源电表" />
								<el-option value="2" label="水表" />
								<el-option value="3" label="气表" />
								<el-option value="4" label="iframe视频" />
								<el-option value="5" label="萤石云视频" />
								<el-option value="6" label="萤石云视频(带云台)" />
								<el-option value="7" label="电子水尺" />
								<el-option value="8" label="温度" />
								<el-option value="9" label="湿度" />
								<el-option value="10" label="烟感" />
								<el-option value="11" label="水浸" />
							</el-select>
						</el-form-item>
					</el-row>
					<template v-if="writeForm.type">
						<template v-if="writeForm.type<=1">
							<el-row>
								<el-form-item prop="PT" label="电压变比">
									<el-input clearable v-model="writeForm.PT" placeholder="" style="width: 100px;" />
								</el-form-item>
								<el-form-item prop="CT" label="电流变比">
									<el-input clearable v-model="writeForm.CT" placeholder="" style="width: 100px;" />
								</el-form-item>
							</el-row>
							<el-row>
								<el-form-item v-if="writeForm.type==0" prop="is_cal_line_lost" label="是否计算线损">
									<el-radio-group v-model="writeForm.is_cal_line_lost">
										<el-radio-button label="1">是</el-radio-button>
										<el-radio-button label="0">否</el-radio-button>
									</el-radio-group>
								</el-form-item>
								<el-form-item v-if="writeForm.type==1" label="是否计算减碳" prop="is_cal_rc">
									<el-radio-group v-model="writeForm.is_cal_rc">
										<el-radio-button label="1">是</el-radio-button>
										<el-radio-button label="0">否</el-radio-button>
									</el-radio-group>
								</el-form-item>
							</el-row>
						</template>
						<el-row v-else-if="writeForm.type==4">
							<el-form-item prop="url" label="iframe视频地址">
								<el-input clearable v-model="writeForm.url" placeholder="" style="width: 400px;" />
							</el-form-item>
						</el-row>
						<el-row v-else-if="writeForm.type>=5&&writeForm.type<=7">
							<el-form-item prop="ezopen_verify_code" label="验证码">
								<el-input clearable v-model="writeForm.ezopen_verify_code" placeholder="" style="width: 100px;" />
							</el-form-item>
							<el-form-item prop="ezopen_device_serial" label="序列号">
								<el-input clearable v-model="writeForm.ezopen_device_serial" placeholder="" style="width: 100px;" />
							</el-form-item>
							<el-form-item prop="ezopen_channel_no" label="频道号">
								<el-input clearable v-model="writeForm.ezopen_channel_no" placeholder="" style="width: 100px;" />
							</el-form-item>
						</el-row>
					</template>
					<h4>监测对象</h4>
					<el-row>
						<el-form-item prop="level" label="监测对象">
							<el-radio-group v-model="writeForm.level" @click="test">
								<el-radio-button v-if="!writeForm.type||writeForm.type<=3" label="1">企业</el-radio-button>
								<el-radio-button v-if="!writeForm.type||writeForm.type<=3" label="2">回路</el-radio-button>
								<el-radio-button label="3">监测点</el-radio-button>
							</el-radio-group>
						</el-form-item>
					</el-row>
					<el-row>
						<el-form-item v-if="writeForm.level>=1" prop="company_id" label="监测企业">
							<el-select v-model="writeForm.company_id" placeholder="请选择" filterable clearable style="width: 180px;" @change="get_select_company_line('main1')">
								<el-option v-for="item in select_company" :key="'main1-select-company-'+item.id" :label="item.name" :value="item.id" />
							</el-select>
						</el-form-item>
						<el-form-item v-if="writeForm.level>=2&&writeForm.company_id>0" prop="line_id" label="监测回路">
							<el-select v-model="writeForm.line_id" placeholder="请选择" filterable clearable style="width: 180px;" @change="get_select_company_point('main1')">
								<el-option v-for="item in select_company_line" :key="'main1-select-line-'+item.id" :label="item.name" :value="item.id" />
							</el-select>
						</el-form-item>
						<el-form-item v-if="writeForm.level==3&&writeForm.company_id>0&&writeForm.line_id>0" prop="point_id" label="监测点">
							<el-select v-model="writeForm.point_id" placeholder="请选择" filterable clearable style="width: 180px;">
								<el-option v-for="item in select_company_point" :key="'main1-select-point-'+item.id" :label="item.name" :value="item.id" />
							</el-select>
						</el-form-item>
						<el-form-item v-if="writeForm.level>0" prop="name" label="监测指标">
							<el-input clearable v-model="writeForm.name" placeholder="" style="width: 180px;" />
						</el-form-item>
					</el-row>
					<template v-if="writeForm.type&&(writeForm.type<=3||writeForm.type>=7)">
						<h4>ModBus网关信息</h4>
						<el-row>
							<el-form-item prop="net" label="网关地址">
								<el-input clearable v-model="writeForm.net" placeholder="" style="width: 100px;" />
							</el-form-item>
							<el-form-item prop="m_addr" label="从机地址">
								<el-input clearable v-model="writeForm.m_addr" placeholder="" style="width: 100px;" />
							</el-form-item>
						</el-row>
						<el-row>
							<el-form-item prop="model" label="ModBus协议">
								<el-select v-model="writeForm.model" placeholder="请选择" filterable clearable style="width: 180px;">
									<el-option v-for="(item,index) in select_model" :key="index" :label="item.model" :value="item.model" />
								</el-select>
							</el-form-item>
						</el-row>
						<h4>报警条件</h4>
						<el-row>
							<el-form-item prop="alert_switch" label="报警开关">
								<el-switch v-model="writeForm.alert_switch" active-value="1" inactive-value="0" />
							</el-form-item>
						</el-row>
						<template v-if="writeForm.alert_switch==1">
							<el-row v-if="writeForm.type<=1">
								<el-form-item prop="quantity_max" label="电量阈值(kWh)">
									<el-input clearable v-model="writeForm.quantity_max" placeholder="" style="width: 100px;" />
								</el-form-item>
								<el-form-item prop="power_max" label="功率阈值(kW)">
									<el-input clearable v-model="writeForm.power_max" placeholder="" style="width: 100px;" />
								</el-form-item>
							</el-row>
							<el-row v-else-if="writeForm.type==10||writeForm.type==11">
								<el-form-item prop="alert_val" label="报警值">
									<el-input clearable v-model="writeForm.alert_val" placeholder="" style="width: 100px;" />
								</el-form-item>
							</el-row>
							<el-row v-else>
								<el-form-item prop="alert_max" label="报警上限">
									<el-input clearable v-model="writeForm.alert_max" placeholder="" style="width: 100px;" />
								</el-form-item>
								<el-form-item prop="alert_min" label="报警下限">
									<el-input clearable v-model="writeForm.alert_min" placeholder="" style="width: 100px;" />
								</el-form-item>
							</el-row>
						</template>

					</template>
					<el-row>
						<el-form-item label=" ">
							<h6>创建时间：{{writeForm.create_time}}<br />修改时间：{{writeForm.update_time}}</h6>
						</el-form-item>
					</el-row>
				</template>
				<!-- 搜索框 -->
				<template #searchForm="{searchForm,show_more_search}">
					<el-form-item prop="sockfd" label="设备状态">
						<el-radio-group v-model="searchForm.sockfd">
							<el-radio-button label="">全部</el-radio-button>
							<el-radio-button label="1">在线</el-radio-button>
							<el-radio-button label="0">离线</el-radio-button>
						</el-radio-group>
					</el-form-item>
					<el-form-item prop="type" label="设备类型">
						<el-select v-model="searchForm.type" filterable clearable>
							<el-option value="" label="全部" />
							<el-option value="0" label="普通电表" />
							<el-option value="1" label="新能源电表" />
							<el-option value="2" label="水表" />
							<el-option value="3" label="气表" />
							<el-option value="4" label="iframe视频" />
							<el-option value="5" label="萤石云视频" />
							<el-option value="6" label="萤石云视频(带云台)" />
							<el-option value="7" label="电子水尺" />
							<el-option value="8" label="温度" />
							<el-option value="9" label="湿度" />
							<el-option value="10" label="烟感" />
							<el-option value="11" label="水浸" />
						</el-select>
					</el-form-item>
					<el-form-item label="监测对象" prop="level">
						<el-radio-group v-model="searchForm.level">
							<el-radio-button label="">全部</el-radio-button>
							<el-radio-button label="1">企业</el-radio-button>
							<el-radio-button label="2">回路</el-radio-button>
							<el-radio-button label="3">监测点</el-radio-button>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="监测指标" prop="name">
						<el-input v-model="searchForm.name" clearable />
					</el-form-item>
					<el-form-item prop="company_id" label="监测企业" v-show="show_more_search">
						<el-select v-model="searchForm.company_id" placeholder="请选择" filterable clearable style="width: 180px;" @change="get_select_company_line_search('main1')">
							<el-option value="" label="全部" />
							<el-option v-for="item in select_company" :key="'main1-sselect-company-'+item.id" :label="item.name" :value="item.id" />
						</el-select>
					</el-form-item>
					<el-form-item prop="line_id" label="监测回路" v-show="show_more_search">
						<el-select v-model="searchForm.line_id" placeholder="请选择" filterable clearable style="width: 180px;" @change="get_select_company_point_search('main1')">
							<el-option value="" label="全部" />
							<el-option v-for="item in select_company_line" :key="'main1-sselect-line-'+item.id" :label="item.name" :value="item.id" />
						</el-select>
					</el-form-item>
					<el-form-item prop="point_id" label="监测点" v-show="show_more_search">
						<el-select v-model="searchForm.point_id" placeholder="请选择" filterable clearable style="width: 180px;">
							<el-option value="" label="全部" />
							<el-option v-for="item in select_company_point" :key="'main1-sselect-point-'+item.id" :label="item.name" :value="item.id" />
						</el-select>
					</el-form-item>
					<el-form-item label="报警开关" prop="alert_switch" v-show="show_more_search">
						<el-radio-group v-model="searchForm.alert_switch">
							<el-radio-button label="">全部</el-radio-button>
							<el-radio-button label="1">开</el-radio-button>
							<el-radio-button label="0">关</el-radio-button>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="网关地址" prop="net" v-show="show_more_search">
						<el-input v-model="searchForm.net" clearable />
					</el-form-item>
					<el-form-item label="从机地址" prop="m_addr" v-show="show_more_search">
						<el-input v-model="searchForm.m_addr" clearable />
					</el-form-item>
				</template>
				<!-- 列表 -->
				<template #table>
					<el-table-column label="基本信息">
						<template slot-scope="scope">
							<h4 style="color:#2C6DD2;margin:5px 0">
								<el-tag size="small" style="margin-right:10px">{{scope.row.level_name}}</el-tag>
								<template v-if="scope.row.level>=1">
									{{scope.row.company_name}} /
								</template>
								<template v-if="scope.row.level>=2">
									{{scope.row.line_name}} /
								</template>
								<template v-if="scope.row.level>=3">
									{{scope.row.point_name}} /
								</template> {{scope.row.name}}
							</h4>
							<p style="margin:5px 0"><i style="color:#2C6DD2" class="iconfont el-icon-wode"></i> 基本信息：{{scope.row.type_name}}
								<template v-if="scope.row.type<=1">
									/ 电压变比{{scope.row.PT}} / 电流变比{{scope.row.CT}}
									<template v-if="scope.row.is_cal_line_lost==1">
										/ 计算线损
									</template>
									<template v-if="scope.row.type==1&&scope.row.is_cal_rc==1">
										/ 计算减碳
									</template>
								</template>
								<template v-else-if="scope.row.type==4">
									/ iframe视频地址 {{scope.row.url}}
								</template>
								<template v-else-if="scope.row.type>=5&&scope.row.type<=7">
									/ 验证码 {{scope.row.ezopen_verify_code}}
									/ 序列号 {{scope.row.ezopen_device_serial}}
									/ 频道号 {{scope.row.ezopen_channel_no}}
								</template>
							</p>
							<p style="margin:5px 0" v-if="scope.row.type<=3||scope.row.type>=7"><i style="color:#2C6DD2" class="iconfont el-icon-wode"></i> 数据来源：实时数值 {{scope.row.cur_val}} /
								<span v-if="scope.row.net&&scope.row.net!=''">
									ModBus网关 网关地址 {{scope.row.net}} /
									从机地址 {{scope.row.m_addr}} /
									协议 {{scope.row.model}}
								</span>
								<span v-else>
									虚拟节点 {{scope.row.level==3?'随机数':'计算子级之和'}}
								</span>
							</p>
							<p style="margin:5px 0" v-if="scope.row.alert_switch==1"><i style="color:#2C6DD2" class="iconfont el-icon-wode"></i> 报警情况：
								<template v-if="scope.row.type<=1">
									<template v-if="scope.row.power_max>0">
										功率超过{{scope.row.power_max}}时<br />
									</template>
									<template v-if="scope.row.quantity_max>0">
										电量超过{{scope.row.quantity_max}}时
									</template>
									<template v-if="scope.row.quantity_max<=0&&scope.row.quantity_max<=0">
										暂无
									</template>
								</template>
								<template v-else-if="scope.row.type==10||scope.row.type==11">
									<template>
										当数值等于{{scope.row.alert_val}}时
									</template>
								</template>
								<template v-else>
									<template v-if="scope.row.alert_max>0">
										当数值高于{{scope.row.alert_max}}时<br />
									</template>
									<template v-if="scope.row.alert_min>0">
										当数值低于{{scope.row.alert_min}}时
									</template>
									<template v-if="scope.row.alert_max<=0&&scope.row.alert_min<=0.000">
										暂无
									</template>
								</template>
								<template v-if="scope.row.alert_event!=''">
									/ 实时报警状态：{{scope.row.alert_event}}
								</template>
							</p>
							<p style="margin:5px 0">
								<el-tag type="success" size="small" effect="dark" style="margin-right:6px;" v-if="scope.row.sockfd>0">设备在线</el-tag>
								<el-tag type="info" size="small" effect="dark" style="margin-right:6px;" v-else>设备离线</el-tag>
								{{scope.row.update_time}}
								<el-link style="float:right" icon="el-icon-delete" type="primary" @click="del_btn('main1', scope.row.id)" v-if="role!='普通管理员'">删除</el-link>
								<el-link style="float:right;margin-right:10px" icon="el-icon-edit" type="primary" @click="edit_btn('main1', scope.row.id)" v-if="role!='普通管理员'">编辑</el-link>
							</p>
						</template>
					</el-table-column>
				</template>
			</madmin>
		</el-tab-pane>
		<el-tab-pane lazy label="ModBus协议管理">
			<madmin style="margin-bottom:10px" ref="main4" title="ModBus协议" table_name="Modbus" table_show_header>
				<!-- 弹出编辑模态窗口 -->
				<template #writeForm="{writeForm}">
					<h4>基本信息</h4>
					<el-form-item prop="model" label="协议名称">
						<el-input clearable v-model="writeForm.model" placeholder="" />
					</el-form-item>
					<h4>Modbus指令</h4>
					<el-row>
						<el-form-item label="功能码" prop="reg_type">
							<el-radio-group v-model="writeForm.reg_type">
								<el-radio-button label="3">03读保持寄存器</el-radio-button>
								<el-radio-button label="4">04读输入寄存器</el-radio-button>
							</el-radio-group>
						</el-form-item>
					</el-row>
					<el-row>
						<el-form-item prop="reg_addr" label="寄存器地址">
							<el-input clearable v-model="writeForm.reg_addr" placeholder="" style="width: 80px;" />
						</el-form-item>
						<el-form-item prop="reg_num" label="寄存器数量">
							<el-input clearable v-model="writeForm.reg_num" placeholder="" style="width: 80px;" />
						</el-form-item>
					</el-row>
					<h4>数据处理</h4>
					<el-row>
						<el-form-item label="字节顺序" prop="byte_end">
							<el-radio-group v-model="writeForm.byte_end">
								<el-radio-button label="0">小端</el-radio-button>
								<el-radio-button label="1">大端</el-radio-button>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="数据类型" prop="data_type">
							<el-radio-group v-model="writeForm.data_type">
								<el-radio-button label="2">整型</el-radio-button>
								<el-radio-button label="0">浮点型</el-radio-button>
								<el-radio-button label="1">字符型</el-radio-button>
								<el-radio-button label="3">BCD码</el-radio-button>

							</el-radio-group>
						</el-form-item>
					</el-row>
					<el-row>
						<el-form-item prop="scale" label="缩放倍率">
							<el-input clearable v-model="writeForm.scale" placeholder="" style="width: 80px;" />
						</el-form-item>
						<el-form-item label="数据存储" prop="insert_table">
							<el-select v-model="writeForm.insert_table" filterable clearable>
								<el-option value="0" label="环境" />
								<el-option value="1" label="A相电压" />
								<el-option value="2" label="B相电压" />
								<el-option value="3" label="C相电压" />
								<el-option value="4" label="A相电流" />
								<el-option value="5" label="B相电流" />
								<el-option value="6" label="C相电流" />
								<el-option value="7" label="总功率" />
								<el-option value="8" label="总电量" />
								<el-option value="9" label="AB功率" />
								<el-option value="10" label="BC功率" />
								<el-option value="11" label="CA功率" />
								<el-option value="12" label="水量" />
								<el-option value="13" label="燃气量" />
							</el-select>
						</el-form-item>
					</el-row>
					<h4 style="margin:12px 0 6px 0;">数据类型转换说明：</h4>
					<p style="margin:0;font-size:12px;">◼ 整型：按无符号整数转换</p>
					<p style="margin:0;font-size:12px;">◼ 浮点数：按IEEE754标准,单精度,4字节转换</p>
					<p style="margin:0;font-size:12px;">◼ 字符型：按ASCII码转换</p>
					<p style="margin:0;font-size:12px;">◼ BCD码：按8421码转换</p>
				</template>
				<!-- 搜索框 -->
				<template #searchForm="{searchForm,show_more_search}">
					<el-form-item label="数据存储" prop="insert_table">
						<el-select v-model="searchForm.insert_table" filterable clearable>
							<el-option value="" label="全部" />
							<el-option value="0" label="环境" />
							<el-option value="1" label="A相电压" />
							<el-option value="2" label="B相电压" />
							<el-option value="3" label="C相电压" />
							<el-option value="4" label="A相电流" />
							<el-option value="5" label="B相电流" />
							<el-option value="6" label="C相电流" />
							<el-option value="7" label="总功率" />
							<el-option value="8" label="总电量" />
							<el-option value="9" label="AB功率" />
							<el-option value="10" label="BC功率" />
							<el-option value="11" label="CA功率" />
							<el-option value="12" label="水量" />
							<el-option value="13" label="燃气量" />
						</el-select>
					</el-form-item>
					<el-form-item label="协议名称" prop="model">
						<el-input v-model="searchForm.model" clearable />
					</el-form-item>

					<el-form-item label="功能码" prop="reg_type" v-show="show_more_search">
						<el-radio-group v-model="searchForm.reg_type">
							<el-radio-button label="">全部</el-radio-button>
							<el-radio-button label="3">03读保持寄存器</el-radio-button>
							<el-radio-button label="4">04读输入寄存器</el-radio-button>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="字节顺序" prop="byte_end" v-show="show_more_search">
						<el-radio-group v-model="searchForm.byte_end">
							<el-radio-button label="">全部</el-radio-button>
							<el-radio-button label="0">小端</el-radio-button>
							<el-radio-button label="1">大端</el-radio-button>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="数值类型" prop="data_type" v-show="show_more_search">
						<el-radio-group v-model="searchForm.data_type">
							<el-radio-button label="">全部</el-radio-button>
							<el-radio-button label="2">整型</el-radio-button>
							<el-radio-button label="0">浮点型</el-radio-button>
							<el-radio-button label="1">字符型</el-radio-button>
							<el-radio-button label="3">BCD码</el-radio-button>
						</el-radio-group>
					</el-form-item>
				</template>
				<!-- 列表 -->
				<template #table>
					<el-table-column align="center" label="协议名称" prop="model" />
					<el-table-column align="center" label="Modbus指令(十进制)">
						<el-table-column align="center" label="功能码(1B)" prop="reg_type" />
						<el-table-column align="center" label="寄存器地址(2B)" prop="reg_addr" />
						<el-table-column align="center" label="寄存器数量(2B)" prop="reg_num" />
					</el-table-column>
					<el-table-column align="center" label="数据处理">
						<el-table-column align="center" label="字节顺序" prop="byte_end" />
						<el-table-column align="center" label="数据类型" prop="data_type" />
						<el-table-column align="center" label="缩放倍率" prop="scale" />
						<el-table-column align="center" label="数据用途" prop="insert_table" />
					</el-table-column>
					<el-table-column align="center" label="操作" width="120">
						<template slot-scope="scope">
							<el-link style="float:right" icon="el-icon-delete" type="primary" @click="del_btn('main4', scope.row.id)" v-if="role!='普通管理员'">删除</el-link>
							<el-link style="float:right;margin-right:10px" icon="el-icon-edit" type="primary" @click="edit_btn('main4', scope.row.id)" v-if="role!='普通管理员'">编辑</el-link>
						</template>
					</el-table-column>
				</template>
			</madmin>
		</el-tab-pane>
		<el-tab-pane lazy label="ModBus网关列表">
			<madmin1 style="margin-bottom:10px" ref="main6" title="网关实时状态" table_name="Net" :table_show_header="true">
				<template #searchForm="{searchForm}">
					<el-form-item label="网关地址" prop="net">
						<el-input v-model="searchForm.net" />
					</el-form-item>
					<el-form-item label="同步时间" prop="refresh_time">
						<el-date-picker v-model="searchForm.refresh_time" type="daterange" :picker-options="time_opt" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd" align="right"></el-date-picker>
					</el-form-item>
				</template>
				<!-- 列表 -->
				<template #table>
					<el-table-column label="网关地址" align="center" prop="net" width="120" />
					<el-table-column label="设备数量" align="center" prop="device_num" width="120" />
					<el-table-column label="网关状态" align="center" prop="status" width="120" />
					<el-table-column label="同步时间" align="center" prop="refresh_time" />
				</template>
			</madmin1>
		</el-tab-pane>
		<el-tab-pane lazy label="萤石云设备列表(From 代理商)">
			<madmin1 style="margin-bottom:10px" ref="main6" title="萤石云设备" table_name="Ezopen" :table_show_header="true">
				<template #searchForm="{searchForm,show_more_search}">
					<el-form-item label="序列号" prop="deviceSerial">
						<el-input v-model="searchForm.deviceSerial" />
					</el-form-item>
					<el-form-item label="型号" prop="deviceType" v-show="show_more_search">
						<el-input v-model="searchForm.deviceType" />
					</el-form-item>
					<el-form-item label="版本" prop="deviceVersion" v-show="show_more_search">
						<el-input v-model="searchForm.deviceVersion" />
					</el-form-item>
					<el-form-item label="投入使用" prop="is_use">
						<el-radio-group v-model="searchForm.is_use">
							<el-radio-button label="">全部</el-radio-button>
							<el-radio-button label="0">未使用</el-radio-button>
							<el-radio-button label="1">使用</el-radio-button>
						</el-radio-group>
					</el-form-item>

					<el-form-item label="运行状态" prop="status">
						<el-radio-group v-model="searchForm.status">
							<el-radio-button label="">全部</el-radio-button>
							<el-radio-button label="0">离线</el-radio-button>
							<el-radio-button label="1">在线</el-radio-button>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="安防状态" prop="defence" v-show="show_more_search">
						<el-radio-group v-model="searchForm.defence">
							<el-radio-button label="">全部</el-radio-button>
							<el-radio-button label="0">IPC撤防/睡眠</el-radio-button>
							<el-radio-button label="1">IPC布防</el-radio-button>
							<el-radio-button label="8">在家</el-radio-button>
							<el-radio-button label="16">外出</el-radio-button>
						</el-radio-group>
					</el-form-item>
				</template>
				<!-- 列表 -->
				<template #table>
					<el-table-column label="序列号" align="center" prop="deviceSerial" width="120" />
					<el-table-column label="投入使用" align="center" prop="is_use" width="120">
						<template slot-scope="scope">
							<el-tag type="success" size="small" effect="dark" style="margin-right:6px;" v-if="scope.row.is_use=='使用'">使用</el-tag>
							<el-tag type="info" size="small" effect="dark" style="margin-right:6px;" v-else>未使用</el-tag>
						</template>
					</el-table-column>
					<el-table-column label="运行状态" align="center" width="120">
						<template slot-scope="scope">
							<el-tag type="success" size="small" effect="dark" style="margin-right:6px;" v-if="scope.row.status=='在线'">设备在线</el-tag>
							<el-tag type="info" size="small" effect="dark" style="margin-right:6px;" v-else>设备离线</el-tag>
						</template>
					</el-table-column>
					<el-table-column label="安防状态" align="center" prop="defence" width="120" />
					<el-table-column label="网络地址" align="center" prop="netAddress" />
					<el-table-column label="型号" align="center" prop="deviceType" />
					<el-table-column label="版本" align="center" prop="deviceVersion" />
				</template>
			</madmin1>
		</el-tab-pane>
	</el-tabs>
</template>
<script>
	import madmin from "../../components/madmin.vue";
	import madmin1 from "../../components/madmin1.vue";
	import muploadAvatar from "../../components/form/muploadAvatar.vue";
	import mechart from "../../components/mechart.vue";
	import request from "../../plugins/axios";
	export default {
		data() {
			return {
				role: window.localStorage.getItem("role"),
				select_company: [],
				select_tag: [],
				select_company_line: [],
				select_company_point: [],
				select_model: [],
				// rank_company_date: [],
				// rank_device_date: [],
				// total: [],
			};
		},
		components: {
			madmin,
			madmin1,
			mechart,
			muploadAvatar,
		},
		mounted() {
			this.get_select_company();
			this.get_sider();
		},
		methods: {
			get_select_company_line(ref_name) {
				request({
					url: "/admin/company_line/select_list",
					data: {
						token: window.localStorage.getItem("token"),
						company_id: this.$refs[ref_name].writeForm.company_id,
					},
				}).then((ret) => {
					if (ret.code == 1) {
						this.select_company_line = ret.data;
						// this.$refs[ref_name].writeForm.line_id = '';
						this.get_select_company_point();
					}
				});
			},

			get_select_company_point(ref_name) {
				request({
					url: "/admin/company_point/select_list",
					data: {
						token: window.localStorage.getItem("token"),
						line_id: this.$refs[ref_name].writeForm.line_id,
					},
				}).then((ret) => {
					if (ret.code == 1) {
						this.select_company_point = ret.data;
						// this.$refs[ref_name].writeForm.point_id = "";
					}
				});
			},
			get_select_company_line_search(ref_name) {
				request({
					url: "/admin/company_line/select_list",
					data: {
						token: window.localStorage.getItem("token"),
						company_id: this.$refs[ref_name].searchForm.company_id,
					},
				}).then((ret) => {
					if (ret.code == 1) {
						this.select_company_line = ret.data;
						// this.$refs[ref_name].searchForm.line_id = "";
						this.get_select_company_point();
					}
				});
			},
			get_select_company_point_search(ref_name) {
				request({
					url: "/admin/company_point/select_list",
					data: {
						token: window.localStorage.getItem("token"),
						line_id: this.$refs[ref_name].searchForm.line_id,
					},
				}).then((ret) => {
					if (ret.code == 1) {
						this.select_company_point = ret.data;
						// this.$refs[ref_name].searchForm.point_id = "";
					}
				});
			},
			// get_sider() {
			// 	request({
			// 		url: "/admin/Stat/base",
			// 		data: {},
			// 	}).then((ret) => {
			// 		if (ret.code == 1) {
			// 			this.rank_company_date = ret.data.rank_company_date;
			// 			this.rank_device_date = ret.data.rank_device_date;
			// 			this.total = ret.data.total;
			// 		}
			// 	});
			// },
			search_btn(ref_name) {
				this.$refs[ref_name].onSearch();
			},
			del_btn(ref_name, id) {
				this.$refs[ref_name].onDelete(id);
			},
			edit_btn(ref_name, id) {
				this.$refs[ref_name].showWriteForm(id);
				if (ref_name == "main1" || ref_name == "main5") {
					setTimeout(() => {
						this.get_select_company_line(ref_name);
					}, 200);
				}
			},
			go_tab(activeName, oldActiveName) {
				// console.log(activeName);
			},
			get_select_company() {
				request({
					url: "/admin/Company/select_list",
					data: {
						token: window.localStorage.getItem("token"),
					},
				}).then((ret) => {
					if (ret.code == 1) {
						this.select_company = ret.data;
					}
				});
				request({
					url: "/admin/company_line/select_list",
					data: {
						token: window.localStorage.getItem("token"),
					},
				}).then((ret) => {
					if (ret.code == 1) {
						this.select_tag = ret.data;
					}
				});
				request({
					url: "/admin/Modbus/select_list",
					data: {
						token: window.localStorage.getItem("token"),
					},
				}).then((ret) => {
					if (ret.code == 1) {
						this.select_model = ret.data;
					}
				});
			},
		},
	};
</script>